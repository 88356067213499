import React, { FormEvent, useState } from 'react';
import {
  Box, Button, Card, Link, TextField, Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import useApplication from '../../context/ApplicationContext';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  frame: {
    margin: 'auto',
    padding: '20px',
  },
  form: {
    display: 'flex',
    flex: '1 1 100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  textField: {
    width: '300px',
    margin: '10px',
  },
});

const PasswordReset = () => {
  const classes = useStyles();
  const { passwordReset } = useApplication();
  const [passwordResetSent, setPasswordResetSent] = useState<boolean>(false);

  const doPasswordReset = (e: FormEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      email: { value: string };
    };

    passwordReset(target.email.value).then(() => setPasswordResetSent(true));
  };

  return (
    <Box className={classes.root} display="flex" flex="1 1 100%" flexDirection="column" justifyContent="center" alignItems="center">
      <Card className={classes.frame}>
        {!passwordResetSent
          ? (
            <form className={classes.form} onSubmit={doPasswordReset}>
              <Typography variant="h4">Passwort zurücksetzen</Typography>
              <TextField margin="dense" className={classes.textField} type="email" id="email" name="email" label="Email" />
              <Box marginTop={2}>
                <Button color="primary" variant="contained" className={classes.textField} type="submit">Passwort zurücksetzen</Button>
                <Box display="flex" justifyContent="space-between" padding={2}>
                  <Link component={RouterLink} to="/signin">Login</Link>
                  <Link component={RouterLink} to="/signup">Registrieren</Link>
                </Box>
              </Box>
            </form>
          )
          : (
            <>
              <Typography variant="h4">Passwort zurückgesetzt!</Typography>
              <Typography>
                Sie sollten in Kürze eine Email mit einem Link zum zurücksetzen des Passworts erhalten.
                <br />
                Sobald Sie das Passwort zurückgesetzt haben, können Sie sich erneut&nbsp;
                <Link component={RouterLink} to="/signin">anmelden</Link>
                .
              </Typography>
            </>
          )}
      </Card>
    </Box>
  );
};

export default PasswordReset;
