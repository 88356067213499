import React, { FormEvent, useState } from 'react';
import {
  Box, Button, Card, Link, TextField, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import useApplication from '../../context/ApplicationContext';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  frame: {
    margin: 'auto',
    padding: '20px',
  },
  form: {
    display: 'flex',
    flex: '1 1 100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  textField: {
    width: '300px',
    margin: '10px',
  },
});

const SignIn = () => {
  const classes = useStyles();
  const { signIn } = useApplication();
  const history = useHistory();
  const [error, setError] = useState<string>('');

  const doAuthenticate = (e: FormEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      email: { value: string };
      password: { value: string };
    };
    signIn(target.email.value, target.password.value)
      .then((user) => {
        if (user.user?.emailVerified) {
          history.push('/');
        } else {
          setError('Sie müssen Ihre Email-Adresse bestätigen, bevor Sie sich anmelden können.');
          user.user?.sendEmailVerification();
        }
      })
      .catch((signInError) => setError(signInError.message));
  };

  return (
    <Box className={classes.root} display="flex" flex="1 1 100%" flexDirection="column" justifyContent="center" alignItems="center">
      <Card className={classes.frame}>
        <form className={classes.form} onSubmit={doAuthenticate}>
          <Typography variant="h4">Login</Typography>
          <TextField margin="dense" className={classes.textField} id="email" name="email" label="Email" type="email" />
          <TextField margin="dense" className={classes.textField} id="password" name="password" label="Passwort" type="password" />
          {error !== '' && (
            <Box marginTop={5}>
              <Typography color="error" variant="caption">{error}</Typography>
            </Box>
          )}
          <Box marginTop={2}>
            <Button color="primary" variant="contained" className={classes.textField} type="submit">Anmelden</Button>
            <Box display="flex" justifyContent="space-between" padding={2}>
              <Link component={RouterLink} to="/signup">Registrieren</Link>
              <Link component={RouterLink} to="/password-reset">Passwort vergessen</Link>
            </Box>
          </Box>
        </form>
      </Card>
    </Box>
  );
};

export default SignIn;
