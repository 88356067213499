import * as React from 'react';
import {
  FC, useContext, useEffect, useState,
} from 'react';
import firebase from 'firebase/app';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';
import { auth } from '../firebase';
import { getFirestoreUser, setFirestoreUser } from '../service/firestore';
import { UserType } from '../model/Types';

interface ApplicationContextType {
  user: UserType | undefined;
  firebaseUser: firebase.User | null;
  signIn: (email: string, password: string) => Promise<firebase.auth.UserCredential>;
  signUp: (email: string, password: string) => Promise<firebase.auth.UserCredential>;
  passwordReset: (email: string) => Promise<void>;
  signOut: () => void;
  routeName: string | undefined;
  changeRouteName: (thisRouteName: string) => void;
  triggerSnackbar: (message: string, severity: Color) => void;
}

const ApplicationContext = React.createContext<ApplicationContextType>({
  user: undefined,
  firebaseUser: null,
  signIn: () => Promise.reject(),
  signUp: () => Promise.reject(),
  passwordReset: () => Promise.reject(),
  signOut: () => {},
  routeName: '',
  changeRouteName: () => {},
  triggerSnackbar: () => {},
});

function Alert(props: AlertProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ApplicationProvider: FC = ({ children }) => {
  const [user, setUser] = useState<UserType | undefined>();
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<Color>('info');
  const [routeName, setRouteName] = useState<string>();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((thisFirebaseUser) => {
      if (thisFirebaseUser) {
        setFirebaseUser(thisFirebaseUser);
        getFirestoreUser(thisFirebaseUser.uid)
          .then((thisUser) => {
            if (thisUser) {
              setUser(thisUser);
            } else {
              const newUser: UserType = {
                name: thisFirebaseUser.email || '',
                role: 'user',
                uid: thisFirebaseUser.uid,
              };
              setFirestoreUser(thisFirebaseUser.uid, newUser).then(() => setUser(newUser));
            }
          });
      }
    });

    return unsubscribe;
  }, []);

  const signIn = async (email: string, password: string) => auth.signInWithEmailAndPassword(email, password);

  const signUp = async (email: string, password: string) => auth.createUserWithEmailAndPassword(email, password);

  const passwordReset = async (email: string) => auth.sendPasswordResetEmail(email);

  const signOut = async () => {
    await auth.signOut();
    window.location.href = '/';
  };

  const changeRouteName = (thisRouteName: string) => {
    setRouteName(thisRouteName);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(!snackbarOpen);
  };

  const triggerSnackbar = (message: string, severity: Color) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const ApplicationSnackbar = () => (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={snackbarOpen}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
    >
      <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
    </Snackbar>
  );

  return (
    <ApplicationContext.Provider
      value={{
        user, firebaseUser, signIn, signUp, signOut, passwordReset, routeName, changeRouteName, triggerSnackbar,
      }}
    >
      {children}
      <ApplicationSnackbar />
    </ApplicationContext.Provider>
  );
};

const useApplication = () => useContext(ApplicationContext);

export default useApplication;
export { ApplicationProvider, ApplicationContext };
