import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface ConfirmDialogProps {
  title: string;
  text: string;
  isOpen: boolean;
  confirmText: string;
  cancelText: string;
  cancelAction: () => void;
  confirmAction: () => void;
}

const ConfirmDialog = ({
  title, text, isOpen, cancelText, confirmText, cancelAction, confirmAction,
}: ConfirmDialogProps) => (
  <Dialog
    open={isOpen}
    onClose={cancelAction}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {text}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={cancelAction} color="primary">
        {cancelText}
      </Button>
      <Button onClick={confirmAction} color="primary" autoFocus>
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
