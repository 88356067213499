import {
  Box, Container, Drawer, Hidden,
} from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../Header/Header';
import Menu from '../Menu/Menu';
import RouteHandler from '../../config/RouteHandler';
import useApplication from '../../context/ApplicationContext';

const drawerWidth = 240;

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  container: {
    // minHeight: '100%',
    marginTop: '40px',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
}));

const BaseLayout = ({ toggleDarkMode }: { toggleDarkMode: () => void }) => {
  const { firebaseUser } = useApplication();
  const classes = useStyle();

  return (
    <>
      <Header toggleDarkMode={toggleDarkMode} />
      <Box className={classes.root}>
        {firebaseUser && firebaseUser.emailVerified && (
          <Hidden smDown implementation="css">
            <nav className={classes.drawer}>

              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                className={classes.drawer}
              >
                <div className={classes.toolbar} />
                <Menu />
              </Drawer>
            </nav>
          </Hidden>
        )}
        <Container className={classes.container}>
          <div className={classes.toolbar} />
          <RouteHandler />
        </Container>
      </Box>
    </>
  );
};

export default BaseLayout;
