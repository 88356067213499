import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import useApplication from '../context/ApplicationContext';
import Home from '../views/Home';
import SignIn from '../components/Login/SignIn';
import SignUp from '../components/Login/SignUp';
import PasswordReset from '../components/Login/PasswordReset';
import UserGroups from '../views/UserGroups';

const RouteHandler = () => {
  const { firebaseUser } = useApplication();

  return (
    <Switch>
      {firebaseUser?.emailVerified && (
      <>
        <Route exact path="/" component={Home} />
        <Route exact path="/usergroups" component={UserGroups} />
        <Route exact path="/profile" component={SignUp} />
      </>
      )}
      <Route exact path="/signin" component={SignIn} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/password-reset" component={PasswordReset} />
      <Redirect path="*" to="/signin" />
    </Switch>
  );
};

export default RouteHandler;
