import React, { useEffect, useState } from 'react';
import {
  Box, Grid, Paper, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import UserGroupCard from '../components/Groups/UserGroupCard';
import { UserGroupType } from '../model/Types';
import AddUserGroup from '../components/Groups/AddUserGroup';
import { getUserGroups } from '../service/firestore';

const useStyles = makeStyles({
  introductionWrapper: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: 5,
  },
});

const UserGroups = () => {
  const [userGroups, setUserGroups] = useState<UserGroupType[]>([]);
  const classes = useStyles();

  useEffect(() => {
    getUserGroups().then((newUserGroups) => setUserGroups(newUserGroups));
  }, []);

  const updateUserGroups = () => {
    getUserGroups().then((newUserGroups) => setUserGroups(newUserGroups));
  };

  return (
    <>
      <Box marginBottom={5}>
        <Paper className={classes.introductionWrapper}>
          <Box paddingRight={5}>
            <Typography variant="h4" className={classes.title}>
              Benutzer-Gruppen
            </Typography>
            <Typography variant="body1">
              Hier kannst du deine Benutzer-Gruppen verwalten. Du kannst Mitglieder in Gruppen einteilen, und den Events Gruppen zuteilen. So kannst du sicherstellen,
              dass die Mitglieder nur die für sie relevanten Events sehen.
            </Typography>
          </Box>
          <Box>
            <AddUserGroup updateUserGroups={updateUserGroups} />
          </Box>
        </Paper>
      </Box>
      <Grid container spacing={3}>
        {userGroups.map((userGroup) => (
          <Grid key={userGroup.uid} item xs={12} md={6} lg={3}>
            <UserGroupCard updateUserGroups={updateUserGroups} userGroup={userGroup} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default UserGroups;
