import React, { useState } from 'react';
import {
  AppBar, Box, Drawer, FormControlLabel, FormGroup, Hidden, IconButton, Switch, Toolbar, Typography, useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import useApplication from '../../context/ApplicationContext';
import Menu from '../Menu/Menu';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: 2,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Header = ({ toggleDarkMode }: { toggleDarkMode: () => void }) => {
  const theme = useTheme();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { firebaseUser, routeName } = useApplication();
  const classes = useStyles();

  const triggerMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      {firebaseUser && firebaseUser.emailVerified && (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Box>
              <Hidden mdUp implementation="css">
                <IconButton onClick={triggerMenu} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <Typography variant="h6" color="inherit">
                {routeName}
              </Typography>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={theme.palette.type === 'dark'} onChange={toggleDarkMode} />}
                  label="Dark Mode"
                />
              </FormGroup>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <Hidden mdUp implementation="css">
        <Drawer open={menuOpen} onClose={triggerMenu}>
          <Menu triggerMenu={triggerMenu} />
        </Drawer>
      </Hidden>
    </>
  );
};

export default Header;
