import React from 'react';
import {
  List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons/';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import routes from '../../config/routes';
import useApplication from '../../context/ApplicationContext';

type IconType = typeof import('@material-ui/icons/index');

interface DynamicIconProps {
  iconName: string,
}

const DynamicIcon = ({ iconName }: DynamicIconProps) => React.createElement(Icons[iconName! as keyof IconType]);

const useStyles = makeStyles({
  listItem: {
    marginRight: 20,
  },
});

const Menu = ({ triggerMenu }: { triggerMenu?: () => void }) => {
  const { push } = useHistory();
  const { signOut, changeRouteName } = useApplication();
  const classes = useStyles();

  const navigate = (path: string, routeName: string) => {
    push(path);
    if (triggerMenu) {
      triggerMenu();
    }
    changeRouteName(routeName);
  };

  return (
    <div className="menu-container">
      <List>
        {routes.filter((route) => route.menu).map((route) => (
          <ListItem className={classes.listItem} key={route.path} button onClick={() => navigate(route.path, route.name)}>
            <ListItemIcon><DynamicIcon iconName={route.icon} /></ListItemIcon>
            <ListItemText primary={route.name} />
          </ListItem>
        ))}
        <ListItem
          className={classes.listItem}
          button
          onClick={() => {
            signOut();
            navigate('/signin', 'Login');
          }}
        >
          <ListItemIcon><DynamicIcon iconName="ExitToApp" /></ListItemIcon>
          <ListItemText primary="Abmelden" />
        </ListItem>
      </List>
    </div>
  );
};

export default Menu;
