import React, { useState } from 'react';
import './App.scss';
import {
  createMuiTheme, CssBaseline, ThemeProvider, useMediaQuery,
} from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { ApplicationProvider } from './context/ApplicationContext';
import BaseLayout from './components/BaseLayout/BaseLayout';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [userDarkMode, setUserDarkMode] = useState<boolean>(prefersDarkMode);

  const toggleDarkMode = () => {
    setUserDarkMode(!userDarkMode);
  };

  const theme = React.useMemo(
    () => createMuiTheme({
      palette: {
        type: userDarkMode ? 'dark' : 'light',
      },
    }),
    [userDarkMode],
  );

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ApplicationProvider>
          <BaseLayout toggleDarkMode={toggleDarkMode} />
        </ApplicationProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
