import {
  Backdrop,
  Box,
  Button, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Fab, InputLabel, TextField, Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { FormEvent, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { saveUserGroupImage } from '../../service/storage';
import useApplication from '../../context/ApplicationContext';
import { UserGroupType } from '../../model/Types';
import { addUserGroup } from '../../service/firestore';
import { getRandomUserGroupString } from '../../service/utils';

interface AddUserGroupProps {
  updateUserGroups: () => void;
}

const AddUserGroup = ({ updateUserGroups }: AddUserGroupProps) => {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [backDropOpen, setBackdropOpen] = useState<boolean>(false);
  const { user } = useApplication();

  const toggleDialog = () => {
    setDialogIsOpen(!dialogIsOpen);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      name: { value: string };
      description: { value: string };
      file: { files?: File[] };
    };
    // eslint-disable-next-line no-console
    console.log('create group!', target.name.value, target.description.value);

    setBackdropOpen(true);
    const code = await getRandomUserGroupString();
    setBackdropOpen(false);

    const newUserGroup: UserGroupType = {
      uid: uuid(),
      name: target.name.value,
      description: target.description.value,
      owner: user,
      code,
    };

    if (target.file.files && target.file.files[0] && user?.uid) {
      newUserGroup.imageName = (target.file.files[0].name);
      saveUserGroupImage(newUserGroup.uid, target.file.files[0]);
    }

    setBackdropOpen(true);
    addUserGroup(newUserGroup).then(() => {
      toggleDialog();
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error.message);
    }).finally(() => {
      setBackdropOpen(false);
      updateUserGroups();
    });
  };

  return (
    <>
      <Fab onClick={toggleDialog} variant="extended" color="primary" aria-label="add">
        <AddIcon />
        <Typography variant="button">
          Gruppe
        </Typography>
      </Fab>
      <Dialog disableBackdropClick disableEscapeKeyDown fullWidth maxWidth="md" open={dialogIsOpen} onClose={toggleDialog} aria-labelledby="form-dialog-title">
        <form onSubmit={onSubmit}>
          <DialogTitle id="form-dialog-title">Gruppe erstellen</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Erstellen Sie hier ihre Benutzergruppe.
            </DialogContentText>
            <TextField autoFocus margin="normal" id="name" label="Name der Benutzergruppe" fullWidth />
            <TextField multiline margin="normal" id="description" label="Beschreibung der Benutzergruppe" fullWidth />

            <Box marginTop={5}>
              <InputLabel>Bild hochladen</InputLabel>
              <input type="file" name="file" id="file" accept="image/png, image/jpeg, image/jpg" />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialog} color="primary">
              Abbrechen
            </Button>
            <Button type="submit" color="primary">
              Erstellen
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Backdrop open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AddUserGroup;
