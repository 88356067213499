import React, { useEffect, useState } from 'react';
import {
  Card, CardActionArea, CardContent, CardHeader, CardMedia, IconButton, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { UserGroupType } from '../../model/Types';
import { deleteUserGroupContent, getUserGroupImageUrl } from '../../service/storage';
import { deleteUserGroup } from '../../service/firestore';
import useApplication from '../../context/ApplicationContext';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import EditUserGroup from './EditUserGroup';
import placeholder from '../../images/placeholder_600x400.svg';

const useStyles = makeStyles({
  card: {
    minHeight: 200,
  },
  media: {
    height: 100,
  },
});

interface UserGroupCardProps {
  userGroup: UserGroupType;
  updateUserGroups: () => void;
}

const UserGroupCard = ({ userGroup, updateUserGroups } : UserGroupCardProps) => {
  const classes = useStyles();
  const [userGroupImage, setUserGroupImage] = useState<string>();
  const { triggerSnackbar } = useApplication();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (userGroup.imageName) {
      getUserGroupImageUrl(userGroup.uid, userGroup.imageName).then((url) => {
        setUserGroupImage(url);
      });
    }
  }, [userGroup]);

  const deleteAction = () => {
    deleteUserGroup(userGroup.uid)
      .then(() => {
        deleteUserGroupContent(userGroup.uid).then(() => {
          triggerSnackbar('Benutzergruppe erfolgreich gelöscht.', 'success');
        }).catch((error) => {
          triggerSnackbar(error.message, 'error');
        });
      }).catch((error) => {
        triggerSnackbar(error.message, 'error');
      })
      .finally(() => {
        updateUserGroups();
      });
  };

  const toggleDialog = () => {
    setDialogIsOpen(!dialogIsOpen);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          title={userGroup.name}
          subheader={userGroup.code}
          action={(
            <IconButton aria-label="settings" onClick={() => setConfirmDialogOpen(true)}>
              <DeleteIcon />
            </IconButton>
                  )}
        />
        <CardActionArea onClick={toggleDialog}>
          <CardMedia className={classes.media} image={userGroupImage || placeholder} />
          <CardContent>
            <Typography>
              {userGroup.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <EditUserGroup updateUserGroups={updateUserGroups} dialogIsOpen={dialogIsOpen} toggleDialog={toggleDialog} userGroup={userGroup} />
      <ConfirmDialog
        title="Benutzergruppe löschen"
        text="Möchten Sie die Benutzergruppe wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden."
        isOpen={confirmDialogOpen}
        confirmText="Benutzergruppe löschen"
        cancelText="Abbrechen"
        cancelAction={() => setConfirmDialogOpen(false)}
        confirmAction={() => {
          deleteAction();
          setConfirmDialogOpen(false);
        }}
      />
    </>
  );
};

export default UserGroupCard;
