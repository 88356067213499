import Home from '../views/Home';
import SignIn from '../components/Login/SignIn';
import SignUp from '../components/Login/SignUp';
import UserGroups from '../views/UserGroups';
import PasswordReset from '../components/Login/PasswordReset';

const routes = [
  {
    name: 'Übersicht',
    path: '/',
    component: Home,
    icon: 'Home',
    menu: true,
    authenticated: true,
  },
  {
    name: 'Benutzer-Gruppen',
    path: '/usergroups',
    component: UserGroups,
    icon: 'FolderShared',
    menu: true,
    authenticated: true,
  },
  {
    name: 'Login',
    path: '/signin',
    component: SignIn,
    icon: 'PersonOutline',
  },
  {
    name: 'Registrieren',
    path: '/signup',
    component: SignUp,
    icon: 'PersonOutline',
  },
  {
    name: 'Passwort zurücksetzen',
    path: '/password-reset',
    component: PasswordReset,
    icon: 'PersonOutline',
  },
];

export default routes;
