import { firestore } from '../firebase';
import { UserGroupType, UserType } from '../model/Types';

export const checkUserGroupCode = async (code: string) => {
  const snapshot = await firestore.collection('userGroups')
    .where('code', '==', code)
    .get();
  return snapshot.empty ? Promise.resolve() : Promise.reject();
};

export const getUserGroupRef = async (userGroupUid: string) => firestore.collection('userGroups')
  .doc(userGroupUid)
  .get()
  .then((item) => item.ref);

export const getUserGroups = async () => firestore.collection('userGroups')
  .get()
  .then((userGroup) => userGroup.docs.map((doc) => doc.data()) as UserGroupType[])
  .catch((error) => Promise.reject(error));

export const addUserGroup = async (userGroup: UserGroupType) => firestore.collection('userGroups').doc(userGroup.uid).set(userGroup);

export const updateUserGroup = async (userGroupUid: string, userGroup: Partial<UserGroupType>) => getUserGroupRef(userGroupUid)
  .then((document) => document.update(userGroup))
  .catch((error) => Promise.reject(Error(error.message)));

export const deleteUserGroup = async (userGroupUid: string) => firestore.collection('userGroups')
  .doc(userGroupUid)
  .get()
  .then((snapshot) => {
    snapshot.ref.delete();
  })
  .catch((error) => Promise.reject(Error(error.message)));

export const getFirestoreUser = async (uid: string) => firestore.collection('users').doc(uid)
  .get()
  .then((user) => Promise.resolve(user.data() as UserType | undefined))
  .catch((error) => Promise.reject(error));

export const setFirestoreUser = async (uid: string, user: UserType) => {
  const doc = await firestore.collection('users').doc(uid);
  doc.set(user).then(() => Promise.resolve('Document set.')).catch((error) => Promise.reject(error));
};

export const updateFirestoreUser = async (uid: string, userPartial: Partial<UserType>) => {
  const docRef = await firestore.collection('users').doc(uid)
    .get()
    .then((query) => query.ref)
    .catch((error) => Promise.reject(error));

  return docRef.update({ ...userPartial });
};
