import React, { FormEvent, useState } from 'react';
import {
  Box, Button, Card, Link, TextField, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import useApplication from '../../context/ApplicationContext';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  frame: {
    margin: 'auto',
    padding: '20px',
  },
  form: {
    display: 'flex',
    flex: '1 1 100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  textField: {
    width: '300px',
    margin: '10px',
  },
});

const SignUp = () => {
  const classes = useStyles();
  const { signUp } = useApplication();
  const [error, setError] = useState<string>('');
  const [verifyEmailSent, setVerifyEmailSent] = useState<boolean>(false);

  const doRegister = (e: FormEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      email: { value: string };
      password: { value: string };
      password2: { value: string };
    };

    if (target.email.value !== '' && target.password.value !== '' && target.password.value !== target.password2.value) {
      setError('Bitte füllen Sie alle Formularfelder korrekt aus.');
    } else {
      signUp(target.email.value, target.password.value)
        .then((user) => {
          user.user?.sendEmailVerification();
          setVerifyEmailSent(true);
        })
        .catch((signUpError) => setError(signUpError.message));
    }
  };

  return (
    <Box className={classes.root} display="flex" flex="1 1 100%" flexDirection="column" justifyContent="center" alignItems="center">
      <Card className={classes.frame}>
        {!verifyEmailSent
          ? (
            <form className={classes.form} onSubmit={doRegister}>
              <Typography variant="h4">Registrieren</Typography>
              <TextField margin="dense" className={classes.textField} id="email" name="email" label="Email" type="email" />
              <TextField margin="dense" className={classes.textField} id="password" name="password" label="Passwort" type="password" />
              <TextField margin="dense" className={classes.textField} id="password2" name="password2" label="Passwort wiederholen" type="password" />
              {error !== '' && (
              <Box marginTop={5}>
                <Typography color="error" variant="caption">{error}</Typography>
              </Box>
              )}
              <Box marginTop={2}>
                <Button color="primary" variant="contained" className={classes.textField} type="submit">Registrieren</Button>
                <Box display="flex" justifyContent="space-between" padding={2}>
                  <Link component={RouterLink} to="/signin">Login</Link>
                  <Link component={RouterLink} to="/password-reset">Passwort vergessen</Link>
                </Box>
              </Box>
            </form>
          )
          : (
            <>
              <Typography variant="h4">Registrierung abgeschlossen!</Typography>
              <Typography>
                Sie sollten in Kürze eine Email mit einem Verifizierungs-Link erhalten.
                <br />
                Sobald Sie Ihre Email verifiziert haben, können Sie sich&nbsp;
                <Link component={RouterLink} to="/signin">anmelden</Link>
                .
              </Typography>
            </>
          )}
      </Card>
    </Box>
  );
};

export default SignUp;
