import { checkUserGroupCode } from './firestore';

// export function mockFunction() {
//   // eslint-disable-next-line no-console
//   console.log('mock');
// }

export async function getRandomUserGroupString() {
  const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 6; i += 1) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  const thisResult: string = await checkUserGroupCode(result)
    .then(() => result)
    .catch(() => getRandomUserGroupString());
  return Promise.resolve(thisResult);
}

export default getRandomUserGroupString();
