import firebase from 'firebase/app';
import { storage } from '../firebase';

export const getUserRef = (uid: string) => storage.ref().child(`users/${uid}`);
export const getUserGroupRef = (userGroupUid: string) => storage.ref().child(`userGroups/${userGroupUid}`);
export const getStorageBucket = storage.ref().bucket;

const uploadHandler = (uploadTask: firebase.storage.UploadTask) => {
  uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // eslint-disable-next-line no-console
      console.log(`Upload is ${progress}% done`);
      switch (snapshot.state) {
        case 'paused': // or 'paused'
          // eslint-disable-next-line no-console
          console.log('Upload is paused');
          break;
        case 'running': // or 'running'
          // eslint-disable-next-line no-console
          console.log('Upload is running');
          break;
        default:
          break;
      }
    },
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          throw Error('Unauthorized usage');
        case 'storage/canceled':
          throw Error('Upload cancelled');
        case 'storage/unknown':
          throw Error(error.serverResponse || 'Error unknown');
        default:
          throw Error('General error');
      }
    },
    () => {
      // eslint-disable-next-line no-console
      console.log('Upload finished');
    });
};

export const getUserGroupImageUrl = (userGroupUid: string, imageName: string) => getUserGroupRef(userGroupUid).child(imageName).getDownloadURL();

export const deleteUserGroupContent = async (userGroupUid: string) => {
  const userGroupRef = getUserGroupRef(userGroupUid);
  await userGroupRef.listAll()
    .then((result) => {
      result.items.forEach((item) => {
        item.delete()
          .then()
          .catch((error) => Promise.reject(Error(error.message)));
      });
    })
    .catch((error) => Promise.reject(Error(error.message)));
  return Promise.resolve();
};

export const saveUserGroupImage = (userGroupUid: string, file: File) => {
  const imageRef = getUserGroupRef(userGroupUid).child(file.name);
  // Listen for state changes, errors, and completion of the upload.
  uploadHandler(imageRef.put(file));
};
